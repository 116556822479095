<template>
    <b-card>
      <b-card-header>
        <i class="icon-note"></i> Section Gold Transaction
        <div class="card-header-actions">
          <a class="card-header-action" href="gold" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <br>
      <table>
          <tr>
              <td><strong>Transaction Date</strong></td>
              <td>{{ this.created_at}}</td>
          </tr>
          <tr>
              <td><strong>User</strong></td>
              <td>{{ this.user }}</td>
          </tr>
          <tr>
              <td><strong>Transaction Type</strong></td>
              <td>{{ this.transaction_type }}</td>
          </tr>
          <tr>
              <td><strong>Invoice</strong></td>
              <td>{{ this.invoice }}</td>
          </tr>
          <tr>
              <td><strong>Description</strong></td>
              <td>{{ this.description }}</td>
          </tr>
          <tr>
              <td><strong>Gold Amount</strong></td>
              <td><strong>{{ this.amount }}</strong></td>
          </tr>
          <tr>
              <td><strong>IDR Amount</strong></td>
              <td>{{ this.idr_amount }}</td>
          </tr>
          <tr>
              <td><strong>Gold Buy Price</strong></td>
              <td>{{ this.buying_rate }}</td>
          </tr>
          <tr>
              <td><strong>Gold Sell Price</strong></td>
              <td>{{ this.selling_rate }}</td>
          </tr>
          <tr>
              <td><strong>Transaction Status</strong></td>
              <td>{{ this.status }}</td>
          </tr>
          <tr>
              <td><strong>Metode Pembayaran</strong></td>
              <td>{{ this.payment_method }}</td>
          </tr>
          <tr>
              <td><strong>Total Transaction Amount</strong></td>
              <td>{{ this.gross_amount }}</td>
          </tr>
      </table>

      <br>

      <b-card-header v-if="have_promotion == true">
        <i class="icon-note"></i> Section Promotion
      </b-card-header>
      <br>
      <table v-if="have_promotion == true">
          <tr>
              <td><strong>Tipe Promosi</strong></td>
              <td>{{ this.type }}</td>
          </tr>
          <tr>
              <td><strong>Nilai Promosi</strong></td>
              <td>{{ this.after_discount }}</td>
          </tr>
          <tr v-if="this.max_amount !== null">
              <td><strong>Nilai Max Promosi</strong></td>
              <td>{{ this.max_amount }}</td>
          </tr>
      </table>
      <b-card-header v-if="have_promotion_code == true">
        <i class="icon-note"></i> Section Promotion Code
      </b-card-header>
      <br>
      <table v-if="have_promotion_code == true">
        <tr>
            <td><strong>Nama Promosi</strong></td>
            <td>{{ promotion_code.name }}</td>
        </tr>
        <tr>
            <td><strong>Kode Promosi</strong></td>
            <td>{{ promotion_code.code }}</td>
        </tr>
        <tr>
            <td><strong>Tipe Promosi</strong></td>
            <td>{{ promotion_code.promotion_type }}</td>
        </tr>
        <tr>
            <td><strong>Nilai Promosi</strong></td>
            <td>{{ promotion_code.amount }}</td>
        </tr>
        <tr v-if="promotion_code.max_amount !== null">
            <td><strong>Nilai Max Promosi</strong></td>
            <td>{{ promotion_code.max_amount }}</td>
        </tr>
      </table>
    </b-card>
</template>

<script>
import axios from  'axios'

  export default {
    data() {
      return {
        created_at: '',
        user: '',
        transaction_type: '',
        invoice: '',
        description: '',
        amount: '',
        idr_amount: '',
        payment_method: '',
        buying_rate: '',
        max_amount: '',
        selling_rate: '',
        have_promotion: false,
        have_promotion_code: false,
        gross_amount: '',
        promotion_code: {
          name: '',
          code: '',
          promotion_type: '',
          amount: '',
          max_amount: ''
        },
        status: '',
          name: '',
          module: '',
          item: '',
          type: '',
          after_discount: '',
          startFrom: '',
          endTo: '',
          user_type: '',
          qty: '',
          isAvailable: '',
          organization: '',
          createdAt: '',
          generatedBy: '',
          remaining: '',
          based: '',
          promotion_type: '',
          max_usage: '',
          max_attempt: '',
          errors: {
            code: '',
            message: '',
            status: ''
          },
      }
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
      this.onFetch()
    },
    methods: {
      async onFetch() {
        await this.$http.get(`golds/` + atob(this.$route.params.id))
          .then((result) => {
            let res = result.data.data
            this.created_at = res.transaction.created_at
            this.user = res.transaction.user
            this.transaction_type = res.transaction.transaction_type
            this.description = res.transaction.description
            this.invoice = res.transaction.invoice
            this.amount = res.transaction.amount
            this.idr_amount = res.transaction.idr_amount
            this.buying_rate = res.transaction.buying_rate
            this.selling_rate = res.transaction.selling_rate
            this.gross_amount = res.transaction.gross_amount
            this.payment_method = res.transaction.payment_method
            this.status = res.transaction.status
            this.have_promotion = res.have_promotion
            if (typeof res.promotions !== 'undefined') {
              this.type = res.promotions.type
              this.after_discount = res.promotions.amount
              this.max_amount = res.promotions.max_amount
            }
            this.have_promotion_code = res.transaction.have_promotion_code
            this.promotion_code.name = res.transaction.promotion_code.name
            this.promotion_code.code = res.transaction.promotion_code.code
            this.promotion_code.max_amount = res.transaction.promotion_code.max_amount
            this.promotion_code.promotion_type = res.transaction.promotion_code.promotion_type
            this.promotion_code.amount = res.transaction.promotion_code.amount
          }).catch((error) => {
            if (error.response) {
              this.errors.code = error.response.status
              this.errors.message = error.response.data.meta.message
              this.errors.status = error.response.data.meta.code
            }
          })
      }
    }
  }
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>
